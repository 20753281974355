import { spreaadService } from '../spreaad-serve'

const serviceUrl = 'flow'
const flowService = {
  async getFlow(date, period, idSite, idGoal) {
    return spreaadService
      .get(`${serviceUrl}/get`, {
        params: {
          date,
          period,
          idSite,
          idGoal,
        },
      })
      .then(res => res.data)
  },

  async fetchStageVisits(idStage, date, period, idSite, idGoal) {
    return spreaadService
      .get(`${serviceUrl}/visits-type`, {
        params: {
          idStage,
          date,
          period,
          idSite,
          idGoal,
        },
      })
      .then(res => res.data)
      .then(({ nb_visits_new, nb_visits_returning }) => ({
        // eslint-disable-next-line camelcase
        visits: nb_visits_new + nb_visits_returning,
        newVisits: nb_visits_new,
        returning: nb_visits_returning,
      }))
  },

  async fetchStageOrigins(idStage, date, period, idSite, idGoal) {
    return spreaadService
      .get(`${serviceUrl}/origins`, {
        params: {
          idStage,
          date,
          period,
          idSite,
          idGoal,
          dataType: 'dataframe',
        },
      })
      .then(res => res.data)
      .then(data => {
        const origins = data.data || []
        const result = origins.map(({ name: label, data: _rows, total }) => {
          const aux = _rows || []
          const detail = aux.map(row => ({
            label: row.label,
            hits: row.nb_visits,
            conversionRate: row.conversion_rate,
            conversions: row.nb_conversions,
          }))
          return {
            label,
            detail,
            conversionRate: total.conversion_rate,
            hits: total.nb_visits,
            revenue: total.revenue,
            conversions: total.nb_conversions,
          }
        })
        return result
      })
  },

  async fetchStageOutlinks(idStage, date, period, idSite, idGoal) {
    return spreaadService
      .get(`${serviceUrl}/outlinks`, {
        params: {
          idStage,
          date,
          period,
          idSite,
          idGoal,
          dataType: 'dataframe',
        },
      })
      .then(res => res.data)
      .then(data => {
        const links = data.data || []
        const result = links.map(({ label: link, nb_visits: hits }) => ({
          link,
          hits,
        }))
        return result
      })
  },

  async fetchAttribution(idStage, date, period, idSite, idGoal) {
    return spreaadService
      .get(`${serviceUrl}/goal-attribution`, {
        params: {
          idStage,
          date,
          period,
          idSite,
          idGoal,
        },
      })
      .then(res => res.data)
      .then(({ segment_attribution, other_segments_attribution }) => {
        const attribution = {
          own: segment_attribution,
          others: other_segments_attribution,
        }
        return attribution
      })
  },
}
export { flowService }
export default flowService
