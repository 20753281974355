/* eslint-disable implicit-arrow-linebreak */
import { spreaadService } from '../spreaad-serve'

const serviceUrl = 'analytics'
const homeService = {
  async fetchBehaviour(date, period, idSite, idGoal) {
    return spreaadService
      .get(`${serviceUrl}/visit`, {
        params: {
          date,
          period,
          idSite,
          idGoal,
        },
      })
      .then(res => res.data)
      .then(response => {
        const {
          nb_visits: total,
          bounce_count: dropout,
          nb_visits_converted: conversions,
        } = response
        return { total, dropout, conversions }
      })
  },
  async fetchConversion(date, period, idSite, idGoal) {
    const PERIOD_LENGTH = {
      day: 15,
      week: 6,
      month: 6,
    }
    return spreaadService
      .get(`${serviceUrl}/conversion`, {
        params: {
          date,
          period,
          idSite,
          idGoal,
          dataType: 'serie',
          periodRange: PERIOD_LENGTH[period],
        },
      })
      .then(res => res.data)
      .then(data => {
        const categories = Object.keys(data || {})
        const series = [
          'conversion_rate',
          'nb_conversions',
          'nb_visits_converted',
          'revenue',
        ].reduce((acc, key) => {
          acc.push({
            name: key,
            data: categories.map(cat => data[cat][key] || 0),
          })
          return acc
        }, [])
        return { categories, series }
      })
  },
  async fetchOrigins(date, period, idSite, idGoal) {
    return spreaadService
      .get(`${serviceUrl}/origins`, {
        params: {
          date,
          period,
          idSite,
          idGoal,
        },
      })
      .then(res => res.data)
      .then(data => {
        const categories = data.data || []
        const result = categories.map(({ name, total, data: rows }) => {
          const detail = rows.map(row => ({
            label: row.label,
            hits: row.nb_visits,
            conversionRate: row.conversion_rate,
            conversions: row.nb_visits_converted,
            segment: row.segment,
          }))
          return {
            label: name,
            detail,
            conversionRate: total.conversion_rate,
            hits: total.nb_visits,
            revenue: total.revenue,
            conversions: total.nb_visits_converted,
          }
        })
        return result
      })
  },
  async fetchOutlinks(date, period, idSite) {
    return spreaadService
      .get(`${serviceUrl}/outlinks`, {
        params: {
          date,
          period,
          idSite,
          dataType: 'dataframe',
        },
      })
      .then(res => res.data)
      .then(data => {
        const outlinks = data.data || []
        return outlinks.map(({ label: link, nb_visits: hits }) => ({
          link,
          hits,
        }))
      })
  },
  async fetchVisitorsMap(date, period, idSite) {
    return spreaadService
      .get(`${serviceUrl}/country`, {
        params: {
          date,
          period,
          idSite,
          dataType: 'dataframe',
        },
      })
      .then(res => res.data)
      .then(data => {
        const countries = data.data || []
        const result = countries.map(
          ({
            label,
            nb_visits: visits,
            bounce_rate: bounce,
            avg_time_on_site: seconds,
          }) => ({
            label,
            visits,
            bounce,
            seconds,
          }),
        )
        return result
      })
  },
  async fetchAttributionOrigins(date, period, idSite, idGoal) {
    const PERIOD_LENGTH = {
      day: 7,
      week: 5,
      month: 5,
    }
    return spreaadService
      .get(`${serviceUrl}/attribution-origins`, {
        params: {
          date,
          period,
          idSite,
          idGoal,
          dataType: 'serie',
          periodRange: PERIOD_LENGTH[period],
        },
      })
      .then(res => res.data)
      .then(res => {
        const categories = Object.keys(res.data || {})
        const { labels } = res

        const getData = (origin, column) =>
          categories.map(cat => {
            // Is empty when return a non array object
            if (Array.isArray(res.data[cat]) === false) {
              return 0
            }
            const serie = res.data[cat].find(x => x.label === origin)
            return serie && serie[column] ? serie[column] : 0
          })

        const series = labels.reduce((acc, origin) => {
          acc.push({
            name: origin,
            data: getData(origin, 'nb_visits_converted'),
            label: {
              conversion_rate: getData(origin, 'conversion_rate'),
              nb_conversions: getData(origin, 'nb_conversions'),
              nb_visits_converted: getData(origin, 'nb_visits_converted'),
              revenue: getData(origin, 'revenue'),
              nb_visits: getData(origin, 'nb_visits'),
            },
          })
          return acc
        }, [])
        return { categories, series }
      })
  },
}
export { homeService }
export default homeService
