import { spreaadService } from '../spreaad-serve'

const serviceUrl = 'filters'
const filterService = {
  async getWebpages() {
    return spreaadService
      .get(`${serviceUrl}/webpages`, {
        params: { limit: 30 },
      })
      .then(res => res.data)
  },
  async getGoals(idSite) {
    return spreaadService
      .get(`${serviceUrl}/goals`, { params: { idSite } })
      .then(res => res.data)
  },
}
export { filterService }
export default filterService
