const USER_GOAL_STORE_NAME = 'user-goals'
const USER_SITES_STORE_NAME = 'user-sites'
const USER_TOKEN_STORE_NAME = 'user-data'

export { USER_GOAL_STORE_NAME, USER_SITES_STORE_NAME, USER_TOKEN_STORE_NAME }
export default {
  USER_GOAL_STORE_NAME,
  USER_SITES_STORE_NAME,
  USER_TOKEN_STORE_NAME,
}
