import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import dataFilter from './data-filter'
import home from './home'
import experimentList from './acquisition/experiment/list'
import experimentEditor from './acquisition/experiment/editor'
import experimentAnalytics from './acquisition/experiment/analytics'
import visits from './visits-log'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    dataFilter,
    home,
    auth,
    experimentList,
    experimentEditor,
    experimentAnalytics,
    visits,
  },
  strict: process.env.DEV,
})
