import Database from './pouchdb/database'

class DatabaseFactory {
  constructor() {
    this.databases = []
  }

  open(name) {
    let db = this.databases[name]
    if (!db) {
      db = new Database(name)
      this.databases[name] = db
    }
    return db
  }

  closeDatabases() {
    this.databases.forEach(db => db.close())
  }
}

const PouchDBPlugin = {
  install: Vue => {
    // eslint-disable-next-line no-param-reassign, func-names
    Vue.prototype.$idb = new DatabaseFactory()
  },
}

export default PouchDBPlugin
