import { spreaadApi } from '@/config'
import axios from '@axios'
import EventBus from '@/libs/eventBus'
import { ON_REQUEST_ERROR } from '@/libs/eventBusConstants'
import { USER_TOKEN_STORE_NAME } from '@/libs/storage-keys'

class APIError extends Error {
  constructor(message, status, statusText = 'UnauthorizedError') {
    super(message)
    this.code = status
    this.name = statusText
  }
}

function getToken() {
  if (localStorage.getItem(USER_TOKEN_STORE_NAME)) {
    const { token_auth: tokenAuth } = JSON.parse(
      localStorage.getItem(USER_TOKEN_STORE_NAME),
    )
    return tokenAuth
  }
  return null
}

const spreaadService = axios.create({
  // You can add your headers here
  // ================================
  baseURL: spreaadApi.host,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
})

// Add a request interceptor
spreaadService.interceptors.request.use(
  config => {
    const accessToken = getToken()

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error),
)

// Add the response observer for each response on all axios instance
spreaadService.interceptors.response.use(
  response => response,
  error => {
    const status = error.response?.status || 500
    const statusText = error.response.statusText || 'Internal Server Error'
    // eslint-disable-next-line operator-linebreak
    const message =
      error.response?.data?.message || error.message || 'Internal Server Error'

    const errorObject = new APIError(message, status, statusText)

    EventBus.$emit(ON_REQUEST_ERROR, errorObject)
    return Promise.reject(errorObject)
  },
)

export { spreaadService }
export default spreaadService
