import PouchDb from 'pouchdb-browser'

export default class Database {
  constructor(name) {
    this.$name = name
    this.db = new PouchDb(name)
  }

  all() {
    return this.db.allDocs({
      include_docs: true,
    })
  }

  load(id) {
    return this.db.get(id)
  }

  save(payload) {
    // eslint-disable-next-line no-underscore-dangle
    if (payload._id) {
      return this.db.put(payload)
    }
    return this.db.post(payload)
  }

  remove({ _id, _rev }) {
    return this.db.remove(_id, _rev)
  }

  close() {
    this.db.close().then(() => {
      // console.log(`Database ${this.$name} closed`)
    })
  }
}
