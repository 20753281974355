import mock from '@/@fake-db/mock'

/* eslint-disable global-require */
const data = {
  flow: [
    {
      id: 1,
      idgoal: '0',
      flowStages: [ // Segments
        {
          idsegment: '1',
          idFlow: '',
          order: 1,
          name: 'Landing',
          definition: 'actionType==pageviews;pageUrl==https%253A%252F%252Fwww.taloselectronics.com%252F',
        },
        {
          idsegment: '2',
          idFlow: '',
          order: 3,
          name: 'Search',
          definition: 'actionType==pageviews;pageUrl=@https%253A%252F%252Fwww.taloselectronics.com%252Fpages%252Fsearch-results-page%253Fq%253D',
        },
        {
          idsegment: '3',
          idFlow: '',
          order: 3,
          name: 'Product Category',
          definition: 'actionType==pageviews;pageUrl=@https%253A%252F%252Fwww.taloselectronics.com%252Fcollections',
        },
        {
          idsegment: '4',
          idFlow: '',
          order: 4,
          name: 'Product',
          definition: 'actionType==pageviews;pageUrl=@%252Fproducts%252F',
        },
        {
          idsegment: '5',
          idFlow: '',
          order: 5,
          name: 'Checkout',
          definition: 'actionType==pageviews;pageUrl=@%252Fcheckouts%252F',
        },
        {
          idsegment: '6',
          idFlow: '',
          order: 6,
          name: 'Cart',
          definition: 'actionType==pageviews;pageUrl=@https%253A%252F%252Fwww.taloselectronics.com%252Fcart',
        },
      ],
    },
  ],
  goals: [{
    idgoal: '0',
    name: 'Purchase',
    icon: 'ShoppingCartIcon',
    definition: 'visitEcommerceStatus==ordered,visitEcommerceStatus==orderedThenAbandonedCart',
  }],
}

/* eslint-disable global-require */
mock.onGet('/goals/data').reply(() => [200, data.goals])
mock.onGet('/flow/0').reply(() => {
  const flow = JSON.parse(JSON.stringify(data.flow[0]))
  const goal = data.goals[0]
  flow.flowStages.push({
    idsegment: '100000',
    idFlow: '-1',
    order: 10000,
    name: goal.name,
    definition: goal.definition,
  })
  return [200, flow]
})
