import { spreaadService } from '../spreaad-serve'

const serviceUrl = 'users'
const userService = {
  async login(user, pass, rememberMe = false) {
    const uri = `${serviceUrl}/login`
    return spreaadService
      .get(uri, {
        params: {
          user,
          pass,
          rememberMe,
        },
      })
      .then(request => ({
        username: request?.data?.username,
        token_auth: request?.data?.value,
      }))
  },
}
export { userService }
export default userService
