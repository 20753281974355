const joi = require('joi').extend(require('@joi/date'))

/**
 * Generate a validation schema using joi to check the type of your environment variables
 */
const envSchema = joi
  .object({
    VUE_APP_FILTER_MAX_DATE: joi
      .date()
      .format('YYYY-MM-DD')
      .max('now')
      .default(new Date()),
    VUE_APP_FILTER_SELECTED_DATE: joi
      .date()
      .format('YYYY-MM-DD')
      .max('now')
      .default(new Date()),
  })
  .unknown()
  .required()

/**
 * Validate the env variables using joi.validate()
 */
const { error, value: envVars } = envSchema.validate(process.env)
if (error) {
  throw new Error(`Config validation error: ${error.message}`)
}

const filterDefaultConfig = {
  maxDate: envVars.VUE_APP_FILTER_MAX_DATE,
  selectedDate: envVars.VUE_APP_FILTER_SELECTED_DATE,
}

export { filterDefaultConfig }
export default filterDefaultConfig
