import { USER_TOKEN_STORE_NAME } from '@/libs/storage-keys'
import { userService } from '@/services/spreaadRestApi'

export default {
  namespaced: true,
  state: {
    username:
      JSON.parse(localStorage.getItem(USER_TOKEN_STORE_NAME))?.username || '',
    token_auth: null,
  },
  getters: {
    getUserName: state => state.username,
    getToken: state => state.token_auth,
  },
  mutations: {
    UPDATE_USER(state, val) {
      state.username = val?.username
      state.token_auth = val?.token_auth
      // Save user to local store
      localStorage.setItem(USER_TOKEN_STORE_NAME, JSON.stringify(state))
    },
    REMOVE_DATA() {
      // Remove session data
      localStorage.removeItem(USER_TOKEN_STORE_NAME)
    },
  },
  actions: {
    login({ commit }, { user, pass, rememberMe = false }) {
      return new Promise((resolve, reject) => {
        userService
          .login(user, pass, rememberMe)
          .then(userData => {
            commit('UPDATE_USER', userData)
            resolve(userData)
          })
          .catch(err => {
            commit('REMOVE_DATA')
            return reject(err)
          })
      })
    },
    removeData({ commit }) {
      commit('REMOVE_DATA')
    },
  },
}
