/* eslint-disable operator-linebreak */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './dashboard'
import visitsLog from './visitsLog'
import experiments from './experiments'
import auth from './auth'

// Prevent router push error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...experiments,
    ...visitsLog,
    ...auth,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!to.matched.some(record => record.meta.resource === 'Auth')) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }

    // Add to URL the query string parameters
    if (
      to.query &&
      Object.keys(to.query).length === 0 &&
      to.query.constructor === Object &&
      Object.entries(to.query).toString() !==
        Object.entries(from.query).toString()
    ) {
      return next({ path: to.path, query: from.query })
    }
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const homeName = getHomeRouteForLoggedInUser().name
    next({ name: homeName, query: from.query })
  }

  return next()
})

export default router
