import { experimentService } from '@/services/spreaadRestApi'

export default {
  namespaced: true,
  state: {
    experiments: [],
    selectedExperiment: {},
  },
  mutations: {
    SET_EXPERIMENTS(state, experiments) {
      state.experiments = experiments
    },
    SET_SELECTED_EXPERIMENT(state, id) {
      state.selectedExperiment = state.experiments.find(
        experiment => experiment.id === id,
      )
    },
  },
  actions: {
    setSelectedExperiments({ commit }, id) {
      commit('SET_SELECTED_EXPERIMENT', id)
    },
    fetchExperiments({ commit, rootGetters }) {
      const idSite = rootGetters['dataFilter/getSiteId']

      return new Promise((resolve, reject) => {
        // Get the experiments list
        experimentService
          .get(idSite)
          .then(response => {
            // Get the experiment metrics for each experiment. This is done in parallel for caching performance reasons.
            // The experiments that are not finished yet, use lower cache time.
            // eslint-disable-next-line arrow-body-style
            const promiseGetMetrics = response.map(async experiment => {
              const experimentMetrics = await experimentService.getExperimentMetrics(
                experiment.id,
              )
              return { ...experiment, ...experimentMetrics }
            })

            Promise.all(promiseGetMetrics).then(experiments => {
              commit('SET_EXPERIMENTS', experiments)
              resolve()
            })
          })
          .catch(err => reject(err))
      })
    },
  },
}
