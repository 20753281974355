import mock from '@/@fake-db/mock'

const data = {
  experiments: [
    {
      id: '32131',
      name: 'Campaña de invierno',
      description: 'Preparar la ventas para el fin de año en México',
      period: {
        from: '2021-08-10',
        to: '2021-12-31',
      },
      metrics: {
        goal: 23,
        conversions: 43,
        investment: 123,
      },
      chart: {
        series: [
          {
            name: 'conversion_rate',
            data: [0.1, 0, 0.3, 0.4, 0.05],
          },
        ],
        categories: ['2020-08', '2020-09', '2020-10', '2020-11', '2020-12'],
      },
    },
    {
      id: '32132',
      name: 'Campaña de Santa Claus',
      description: 'Probar el impacto de santa y coca cola',
      period: {
        from: '2021-10-10',
        to: '2021-12-31',
      },
      metrics: {
        goal: 140,
        conversions: 93,
        investment: 653,
      },
      chart: {
        series: [
          {
            name: 'conversion_rate',
            data: [0.8, 0.3, 0.1, 0.03, 0.08],
          },
        ],
        categories: ['2020-10', '2020-10', '2020-11', '2020-11', '2020-12'],
      },
    },
    {
      id: '32133',
      name: 'Campaña de fiestas patrias',
      description: 'Viva México',
      period: {
        from: '2021-09-01',
        to: '2021-09-16',
      },
      metrics: {
        goal: 253,
        conversions: 73,
        investment: 4300,
      },
      chart: {
        series: [
          {
            name: 'conversion_rate',
            data: [0.1, 0, 0.3, 0.4, 0.05, 0.9],
          },
        ],
        categories: ['2020-09-01', '2020-09-02', '2020-09-03', '2020-09-04', '2020-09-05', '2020-09-06'],
      },
    },
  ],
}

mock.onGet('/experiments')
  .reply(() => [200, data.experiments])
mock.onPost('/experiments')
  .reply(() => [200, {
    status: 'OK',
    message: 'Experiment created',
  }])
