import { experimentService } from '@/services/spreaadRestApi'

export default {
  namespaced: true,
  state: {
    experiment: {},
    efforts: [],
  },
  mutations: {
    SET_EXPERIMENT(state, experiment) {
      state.experiment = { ...state.experiment, ...experiment }
    },
    SET_EFFORT(state, efforts) {
      state.efforts = efforts
    },
  },
  actions: {
    fetchEffortAnalytics({ commit }, id) {
      return new Promise((resolve, reject) => {
        experimentService
          .getEffortAnalytics(id)
          .then(response => {
            const { efforts, ...experiment } = response
            commit('SET_EXPERIMENT', experiment)
            commit('SET_EFFORT', efforts)
            resolve()
          })
          .catch(err => reject(err))
      })
    },
    fetchExperimentMetrics({ commit }, id) {
      return new Promise((resolve, reject) => {
        experimentService
          .getExperimentMetrics(id)
          .then(experiment => {
            commit('SET_EXPERIMENT', experiment)
            resolve()
          })
          .catch(err => reject(err))
      })
    },
  },
}
