import router from '@/router'
import { filterDefaultConfig } from '@/config'
import {
  USER_GOAL_STORE_NAME,
  USER_SITES_STORE_NAME,
  USER_TOKEN_STORE_NAME,
} from '@/libs/storage-keys'
import { filterService } from '@/services/spreaadRestApi'

const urlParams = new URLSearchParams(window.location.search)

const defaultDate = new Date(
  // eslint-disable-next-line operator-linebreak
  filterDefaultConfig.selectedDate.getTime() -
    new Date().getTimezoneOffset() * 60000,
)
  .toISOString()
  .slice(0, 10)

export default {
  namespaced: true,
  state: {
    goal: {
      idgoal: -1,
    },
    goals: [],
    sites: [],
    period: urlParams.get('period') || 'day',
    site: {
      idsite: '',
      name: '',
      created: '',
      url: '',
    },
    date: urlParams.get('date') || defaultDate,
  },
  getters: {
    getDate: state => state.date,
    getPeriod: state => state.period,
    getGoal: state => state.goal,
    getSite: state => state.site,
    getSiteId: state => state.site && state.site.idsite,
    getGoalId: state => state.goal && state.goal.idgoal,
    getGoalById: state => goalId => {
      const ref = state.goals || []
      return ref.find(goal => goal.idgoal === goalId)
    },
  },
  mutations: {
    UPDATE_GOALS(state, val) {
      state.goals = Object.assign(state.goals, val)
      // Save user goals to local store
      localStorage.setItem(USER_GOAL_STORE_NAME, JSON.stringify(state.goals))
    },
    UPDATE_SITES(state, val) {
      state.sites = Object.assign(state.sites, val)
      // Save user sites to local store
      localStorage.setItem(USER_SITES_STORE_NAME, JSON.stringify(state.sites))
    },
    UPDATE_GOAL_FILTER(state, val) {
      // To prevent vue reactivity bug
      state.goal = { ...state.goal, ...val }
    },
    UPDATE_SITE_FILTER(state, val) {
      // To prevent vue reactivity bug
      state.site = { ...state.site, ...val }
    },
    UPDATE_PERIOD(state, val) {
      state.period = val
    },
    UPDATE_DATE(state, val) {
      state.date = val
    },
    REMOVE_DATA() {
      // Remove session data
      localStorage.removeItem(USER_SITES_STORE_NAME)
      localStorage.removeItem(USER_GOAL_STORE_NAME)
      localStorage.removeItem(USER_TOKEN_STORE_NAME)
    },
  },
  actions: {
    updateSites({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        filterService
          .getWebpages()
          .then(sites => {
            commit('UPDATE_SITES', sites)
            dispatch('INIT_STORE', false).then(() => resolve())
          })
          .catch(err => {
            commit('REMOVE_DATA')
            return reject(err)
          })
      })
    },
    updateGoals({ commit }, goals) {
      commit('UPDATE_GOALS', goals)
    },
    updateGoal({ dispatch, commit }, { selectedGoal, redirect = true }) {
      commit('UPDATE_GOAL_FILTER', selectedGoal)
      if (redirect) {
        dispatch('pushStateInUrl')
      }
    },
    updateSite({ dispatch, commit }, { selectedSite, redirect = true }) {
      commit('UPDATE_SITE_FILTER', selectedSite)
      if (redirect) {
        dispatch('pushStateInUrl')
      }
    },
    updatePeriod({ dispatch, commit }, { date, period }) {
      commit('UPDATE_PERIOD', period)
      commit('UPDATE_DATE', date)
      return dispatch('pushStateInUrl')
    },
    pushStateInUrl({ getters, rootGetters }) {
      const query = {
        site: getters.getSiteId,
      }

      const showGoalPicker = rootGetters['appConfig/showGoalPicker']
      const showDatePicker = rootGetters['appConfig/showDatePicker']

      if (showGoalPicker) {
        query.goal = getters.getGoalId
      }
      if (showDatePicker) {
        query.date = getters.getDate
        query.period = getters.getPeriod
      }

      return router.push({
        path: window.location.pathname,
        query,
      })
    },
    INIT_STORE({ state, commit, dispatch }, redirect = true) {
      return new Promise(resolve => {
        // Init selected goal
        if (localStorage.getItem(USER_GOAL_STORE_NAME)) {
          // Get all goal data from local store
          commit(
            'UPDATE_GOALS',
            JSON.parse(localStorage.getItem(USER_GOAL_STORE_NAME)),
          )

          // Set default goal
          let goal = state.goals[0]
          if (urlParams.get('goal')) {
            goal = state.goals.find(
              x => x.idgoal.toString() === urlParams.get('goal'),
            )
          }
          if (goal) {
            dispatch('updateGoal', { selectedGoal: goal, redirect: false })
          }
        }

        // Init selected site
        if (localStorage.getItem(USER_SITES_STORE_NAME)) {
          // Get all sites data from local store
          commit(
            'UPDATE_SITES',
            JSON.parse(localStorage.getItem(USER_SITES_STORE_NAME)),
          )

          // Set default site
          let site = state.sites[0]
          if (urlParams.get('site')) {
            site = state.sites.find(
              x => x.idsite.toString() === urlParams.get('site'),
            )
          }
          if (site) {
            dispatch('updateSite', { selectedSite: site, redirect: false })
          }
        }

        // Init url
        if (redirect) {
          dispatch('pushStateInUrl').finally(() => {
            resolve()
          })
        } else {
          resolve()
        }
      })
    },
    removeData({ commit }) {
      commit('REMOVE_DATA')
    },
  },
}
