import { homeService, flowService } from '@/services/spreaadRestApi'

const sitePeriodDateGoal = rootGetters => ({
  idSite: rootGetters['dataFilter/getSiteId'],
  period: rootGetters['dataFilter/getPeriod'],
  date: rootGetters['dataFilter/getDate'],
  idGoal: rootGetters['dataFilter/getGoalId'],
})

export default {
  namespaced: true,
  actions: {
    fetchBehaviour({ rootGetters }) {
      const { idSite, period, date, idGoal } = sitePeriodDateGoal(rootGetters)
      return homeService.fetchBehaviour(date, period, idSite, idGoal)
    },
    fetchConversion({ rootGetters }) {
      const { idSite, period, date, idGoal } = sitePeriodDateGoal(rootGetters)
      return homeService.fetchConversion(date, period, idSite, idGoal)
    },
    fetchAttributionOrigins({ rootGetters }) {
      const { idSite, period, date, idGoal } = sitePeriodDateGoal(rootGetters)
      return homeService.fetchAttributionOrigins(date, period, idSite, idGoal)
    },
    fetchOrigins({ rootGetters }, date) {
      const { idSite, period, date: defaultDate, idGoal } = sitePeriodDateGoal(
        rootGetters,
      )
      return homeService.fetchOrigins(
        date || defaultDate,
        period,
        idSite,
        idGoal,
      )
    },
    fetchOutlinks({ rootGetters }) {
      const { idSite, period, date } = sitePeriodDateGoal(rootGetters)
      return homeService.fetchOutlinks(date, period, idSite)
    },
    fetchVisitorsMap({ rootGetters }) {
      const { idSite, period, date } = sitePeriodDateGoal(rootGetters)
      return homeService.fetchVisitorsMap(date, period, idSite)
    },
    fetchStageVisits({ rootGetters }, { id }) {
      const { idSite, period, date, idGoal } = sitePeriodDateGoal(rootGetters)
      return flowService.fetchStageVisits(id, date, period, idSite, idGoal)
    },
    fetchStageOrigins({ rootGetters }, { id }) {
      const { idSite, period, date, idGoal } = sitePeriodDateGoal(rootGetters)
      return flowService.fetchStageOrigins(id, date, period, idSite, idGoal)
    },
    fetchStageOutlinks({ rootGetters }, { id }) {
      const { idSite, period, date, idGoal } = sitePeriodDateGoal(rootGetters)
      return flowService.fetchStageOutlinks(id, date, period, idSite, idGoal)
    },
    fetchAttribution({ rootGetters }, { id }) {
      const { idSite, period, date, idGoal } = sitePeriodDateGoal(rootGetters)
      return flowService.fetchAttribution(id, date, period, idSite, idGoal)
    },
  },
}
