import { spreaadService } from '../spreaad-serve'

const serviceUrl = 'experiments'
const experimentService = {
  get: async idSite => {
    const uri = `${serviceUrl}/site/${idSite}`
    return spreaadService
      .get(uri)
      .then(res => res.data)
      .then(data => {
        const experiments = data.map(experiment => {
          const { metrics, ...formattedExperiment } = experiment
          const categories = Object.keys(metrics || {})
          const series = [
            'conversion_rate',
            'nb_conversions',
            'nb_visits_converted',
            'revenue',
          ].reduce((acc, key) => {
            acc.push({
              name: key,
              data: categories.map(cat => metrics[cat][key] || 0),
            })
            return acc
          }, [])

          formattedExperiment.chart = {
            categories,
            series,
          }
          return formattedExperiment
        })

        return experiments
      })
  },

  getById: async id => {
    const uri = `${serviceUrl}/${id}`
    return spreaadService.get(uri).then(res => res.data)
  },

  /* eslint-disable no-param-reassign */
  createOrUpdate: async experiment => {
    const uri = `${serviceUrl}`

    if (experiment.endDate) {
      // eslint-disable-next-line prefer-destructuring
      experiment.endDate = experiment.endDate.split('T')[0]
    }

    // Create
    if (experiment.id) {
      return spreaadService.put(uri, experiment).then(res => res.data)
    }
    // Update
    delete experiment.id
    return spreaadService.post(uri, experiment).then(res => res.data)
  },

  delete: async id => {
    const uri = `${serviceUrl}/${id}`

    return spreaadService.delete(uri).then(res => res.data)
  },

  getEffortAnalytics: async id => {
    const uri = `${serviceUrl}/${id}/effort/analytics`
    return spreaadService
      .get(uri)
      .then(res => res.data)
      .then(data => {
        data.efforts = data.efforts.map(row => {
          row.metrics = {
            visits: row.metrics.nb_visits,
            conversionRate: row.metrics.conversion_rate,
            conversions: row.metrics.nb_conversions,
            revenue: row.metrics.revenue,
            cac: row.metrics.cac,
          }
          return row
        })
        return data
      })
  },

  getExperimentMetrics: async id => {
    const uri = `${serviceUrl}/${id}/metrics`
    return spreaadService
      .get(uri)
      .then(res => res.data)
      .then(experiment => {
        const { metrics, ...formattedExperiment } = experiment
        const categories = Object.keys(metrics || {})
        const series = [
          'conversion_rate',
          'nb_conversions',
          'nb_visits_converted',
          'revenue',
        ].reduce((acc, key) => {
          acc.push({
            name: key,
            data: categories.map(cat => metrics[cat][key] || 0),
          })
          return acc
        }, [])

        formattedExperiment.chart = {
          categories,
          series,
        }
        return formattedExperiment
      })
  },
}
export { experimentService }
export default experimentService
