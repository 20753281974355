const joi = require('joi')

/**
 * Generate a validation schema using joi to check the type of your environment variables
 */
const envSchema = joi
  .object({
    NODE_ENV: joi.string().allow('development', 'production', 'test'),
    VUE_APP_API_HOST: joi.string().required(),
  })
  .unknown()
  .required()

/**
 * Validate the env variables using Schema.validate()
 */
const { error, value: envVars } = envSchema.validate(process.env)
if (error) {
  throw new Error(`Config validation error: ${error.message}`)
}

const env = envVars.NODE_ENV
const isDevelopment = envVars.NODE_ENV === 'development'
const spreaadApi = {
  host: envVars.VUE_APP_API_HOST,
}

export { env, isDevelopment, spreaadApi }
