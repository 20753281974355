import axios from 'axios'
import EventBus from '@/libs/eventBus'
import { ON_STOP_OVERLAY, ON_START_OVERLAY } from '@/libs/eventBusConstants'
import { finalize, NEVER, share } from 'rxjs'

const requestObserver = {
  activeSubscriptions: [],
  fulfilledObserver: NEVER.pipe(
    // If there is no active subscription, emit the stop overlay event
    finalize(() => {
      EventBus.$emit(ON_STOP_OVERLAY)
    }),
    share(),
  ),
  subscribe() {
    // If there is no active subscription, emit the loading overlay event
    if (this.activeSubscriptions.length === 0) {
      EventBus.$emit(ON_START_OVERLAY)
    }

    // Add the subscription to the list of active subscriptions
    this.activeSubscriptions.push(this.fulfilledObserver.subscribe())
  },

  unsubscribe() {
    // Remove the subscription from the list of active subscriptions
    const subscription = this.activeSubscriptions.pop()
    if (subscription) {
      subscription.unsubscribe()
    }
  },
}

// Add the request observer for each request on all axios instance
axios.interceptors.request.use(
  config => {
    requestObserver.subscribe()
    return config
  },
  error => Promise.reject(error),
)

// Add the response observer for each response on all axios instance
axios.interceptors.response.use(
  response => {
    requestObserver.unsubscribe()
    return response
  },
  error => {
    requestObserver.unsubscribe()
    return Promise.reject(error)
  },
)

// axios.create creates a new Axios object that does not copy the global interceptors
// this override fixes that
const orgCreate = axios.create
axios.create = config => {
  const result = orgCreate.call(this, config)
  axios.interceptors.request.handlers.forEach(handler => {
    result.interceptors.request.use(handler.fulfilled, handler.rejected)
  })
  axios.interceptors.response.handlers.forEach(handler => {
    result.interceptors.response.use(handler.fulfilled, handler.rejected)
  })
  return result
}

export default axios
