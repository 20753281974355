import Vue from 'vue'

import {
  EDITOR_NO_CHANGES,
  EDITOR_ON_CREATE,
  EDITOR_ON_UPDATE,
} from '../acquisiton-constants'
import { experimentService } from '@/services/spreaadRestApi'

export default {
  namespaced: true,
  state: {
    experiment: {},
    editor: {
      id: null,
      name: '',
      description: '',
      idgoal: null,
      expectedConversions: 0,
      startDate: null,
      endDate: null,
      efforts: [],
    },
  },
  getters: {
    getEditorCost: state => {
      const efforts = state.editor.efforts || []
      return efforts.reduce((sum, { cost }) => sum + cost, 0)
    },

    getSavedVariants: state => {
      let savedVariants = state.editor.efforts.map((effort, index) => ({
        ...effort,
        index,
      }))

      savedVariants = savedVariants.filter(
        variant => variant.status === EDITOR_NO_CHANGES,
      )
      return savedVariants
    },
    getEditingVariants: state => {
      let getEditingVariants = state.editor.efforts.map((effort, index) => ({
        ...effort,
        index,
      }))
      getEditingVariants = getEditingVariants.filter(
        variant => variant.status !== EDITOR_NO_CHANGES,
      )
      return getEditingVariants
    },

    /**
     * Collect other existent effort names, validation check if name on edition is unique
     */
    getNames: state => index => {
      const others = state.editor.efforts.filter((val, pos) => index !== pos)
      return others.map(({ name }) => name)
    },
    isEditable: state => index => {
      const { status } = state.editor.efforts[index]
      return status === EDITOR_NO_CHANGES
    },
    // eslint-disable-next-line arrow-body-style
    isSavable: ({ editor }) => {
      return !editor.efforts.some(({ status }) => status !== EDITOR_NO_CHANGES)
    },
    /**
     * You can delete an experiment only if has an identifier,
     * true if editor has id value, false otherwise
     */
    isDeleteable: ({ editor }) => !!editor.id,
  },
  mutations: {
    CLEAR_EDITOR(state) {
      state.editor = {
        id: null,
        name: '',
        description: '',
        idgoal: null,
        expectedConversions: 0,
        startDate: null,
        efforts: [],
      }
    },
    FILL_EDITOR(state, doc) {
      // To prevent vue reactivity bug
      state.editor = { ...state.editor, ...doc }
    },
    SETUP_EFFORT(state) {
      state.editor.efforts.push({
        name: '',
        type: '',
        cost: 0,
        url: '',
        source: '',
        content: '',
        term: '',
        urlUtm: null,
        status: EDITOR_ON_CREATE,
      })
    },
    DUPLICATE_EFFORT(state, { index }) {
      // Remove id to prevent error on saving
      const { id, ...effortToDuplicate } = state.editor.efforts[index]
      state.editor.efforts.push({
        ...effortToDuplicate,
        urlUtm: null,
        status: EDITOR_ON_CREATE,
      })
    },
    SET_EXPERIMENT(state, experiment) {
      state.experiment = experiment
    },
    UPDATE_EXPERIMENT(state, payload) {
      // To prevent vue reactivity bug
      state.editor = { ...state.editor, ...payload }
    },
    UPDATE_EFFORT({ editor }, { index, payload }) {
      const effort = editor.efforts[index] || {}
      // To prevent vue reactivity bug
      Vue.set(editor.efforts, index, {
        ...effort,
        ...payload,
      })
    },
    REMOVE_EFFORT({ editor: { efforts } }, index) {
      efforts.splice(index, 1)
    },
  },
  actions: {
    /**
     * Setup new experiment, empty detail and efforts
     */
    resetEditor({ commit, rootGetters }) {
      const idSite = rootGetters['dataFilter/getSiteId']

      commit('CLEAR_EDITOR')
      commit('SETUP_EFFORT')

      commit('UPDATE_EXPERIMENT', { idsite: idSite })
    },
    addNewEffort({ commit }) {
      commit('SETUP_EFFORT')
    },
    stageEffort({ commit }, { index }) {
      commit('UPDATE_EFFORT', {
        index,
        payload: {
          status: EDITOR_NO_CHANGES,
        },
      })
    },
    updateEffort({ commit }, { index, payload }) {
      commit('UPDATE_EFFORT', {
        index,
        payload,
      })
    },

    updateExperiment({ commit }, payload) {
      commit('UPDATE_EXPERIMENT', payload)
    },
    editEffort({ commit }, index) {
      commit('UPDATE_EFFORT', {
        index,
        payload: {
          status: EDITOR_ON_UPDATE,
        },
      })
    },
    cancelEditEffort({ commit }, index) {
      commit('UPDATE_EFFORT', {
        index,
        payload: {
          status: EDITOR_NO_CHANGES,
        },
      })
    },
    duplicateEffort({ commit }, index) {
      commit('DUPLICATE_EFFORT', {
        index,
      })
    },
    removeEffort({ commit }, index) {
      commit('REMOVE_EFFORT', index)
    },
    async saveExperiment({ state, getters }) {
      const payload = {
        ...state.editor,
        investment: getters.getEditorCost,
      }
      // Remove status from payload
      payload.efforts = payload.efforts.map(({ status, ...rest }) => rest)

      // If experiment is new, create it
      await experimentService.createOrUpdate(payload)
    },
    fetchExperiment({ commit }, id) {
      return new Promise((resolve, reject) => {
        experimentService
          .getById(id)
          .then(response => {
            commit('SET_EXPERIMENT', response)
            response.efforts.forEach(effort => {
              // Create sintetic key to help in refresh mechanism
              Object.assign(effort, {
                status: EDITOR_NO_CHANGES,
              })
            })
            commit('FILL_EDITOR', response)
            resolve()
          })
          .catch(err => reject(err))
      })
    },
    /**
     * Remove current experiment in editor, identifiers are part
     * of applications state then is better to get them from vuex
     */
    deleteExperiment({ state, commit }) {
      return new Promise((resolve, reject) => {
        const { id } = state.editor

        experimentService
          .delete(id)
          .then(response => {
            commit('CLEAR_EDITOR')
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
  },
}
