/* eslint-disable no-unused-vars */
import { visitService } from '@/services/spreaadRestApi'

export default {
  namespaced: true,
  state: {
    visitorLog: [],
    totalVisits: 1000,
    filter: {
      period: null,
      date: null,
      idGoal: null,
      segment: null,
      idEffort: null,
    },
  },
  getters: {
    getFilters: (state, _getters, _rootState, rootGetters) => ({
      idSite: rootGetters['dataFilter/getSiteId'],
      period: state.filter.period || rootGetters['dataFilter/getPeriod'],
      date: state.filter.date || rootGetters['dataFilter/getDate'],
      idGoal: state.filter.idGoal || rootGetters['dataFilter/getGoalId'],
      idEffort: state.filter.idEffort,
    }),
  },
  mutations: {
    CLEAR_STATE(state) {
      state.filter = {
        period: null,
        date: null,
        idGoal: null,
        segment: null,
        idEffort: null,
      }
      state.visitorLog = []
    },
    SET_DEFAULT_FILTERS(state, payload) {
      // To prevent vue reactivity bug
      state.filter = { ...state.filter, ...payload }
    },
    SET_VISIT_LOG(state, { meta, data }) {
      state.visitorLog = data
      state.totalVisits = meta.totalVisits
    },
  },
  actions: {
    setDefaultFilters({ commit }, payload = {}) {
      commit('CLEAR_STATE')
      commit('SET_DEFAULT_FILTERS', payload)
    },
    clearVisitLog({ commit }) {
      commit('CLEAR_STATE')
    },
    fetchVisitLog(
      { getters, commit, state },
      { perPage, page = null, filtersSegment = null },
    ) {
      // Get filters
      const { idSite, period, date, idGoal, idEffort } = getters.getFilters

      // Join segments
      const filterSegmentString = [filtersSegment, state.filter.segment]
        .filter(x => x !== '' && x != null)
        .join(';')

      // Get visits log
      visitService
        .getLog(
          idSite,
          period,
          date,
          idGoal,
          idEffort,
          perPage, // filter_limit
          perPage * (page - 1), // filter_offset
          filterSegmentString,
        )
        .then(response => {
          commit('SET_VISIT_LOG', response)
        })
    },
  },
}
