/* eslint-disable implicit-arrow-linebreak */
export default [
  {
    path: '/acquisition',
    component: () => import('@/views/Acquisition.vue'),
    children: [
      {
        path: '',
        name: 'acquisition',
        component: () => import('@/views/acquisition/Experiments.vue'),
        meta: {
          pageTitle: 'Home',
          navActiveLink: 'acquisition',
          breadcrumb: [
            {
              text: 'Experiments',
              active: true,
            },
          ],
        },
      },
      {
        path: 'experiment/:id',
        name: 'editExperiment',
        component: () =>
          import('@/views/acquisition/experiment/ExperimentEditorLayout.vue'),
        meta: {
          pageTitle: 'Acquisition',
          navActiveLink: 'acquisition',
          breadcrumb: [
            {
              text: 'Experiment',
              to: { name: 'acquisition' },
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        },
      },
      {
        path: 'experiment/',
        name: 'newExperiment',
        component: () =>
          import('@/views/acquisition/experiment/ExperimentEditorLayout.vue'),
        meta: {
          pageTitle: 'Acquisition',
          navActiveLink: 'acquisition',
          breadcrumb: [
            {
              text: 'Experiment',
              to: { name: 'acquisition' },
            },
            {
              text: 'New',
              active: true,
            },
          ],
        },
      },
      {
        path: 'experiment/:id/analytic',
        name: 'experimentFullView',
        component: () => import('@/views/acquisition/fullView/index.vue'),
        meta: {
          pageTitle: 'Acquisition',
          navActiveLink: 'acquisition',
          breadcrumb: [
            {
              text: 'Experiment',
              to: '/acquisition/',
            },
            {
              text: 'Analytic',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
