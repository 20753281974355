import { spreaadService } from '../spreaad-serve'

const serviceUrl = 'visits'
const visitService = {
  getLog: async (
    idSite,
    period,
    date,
    idGoal,
    idEffort = null,
    filter_limit = 30,
    filter_offset = 0,
    segment = null,
  ) => {
    const uri = `${serviceUrl}/log/` // ${idSite}
    return spreaadService
      .get(uri, {
        params: {
          period,
          date,
          idGoal,
          idEffort,
          filter_limit,
          filter_offset,
          segment,
          idSite,
        },
      })
      .then(res => res.data)
  },
}
export { visitService }
export default visitService
